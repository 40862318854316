<template>
  <div class="card-description">
    <p class="card-title">{{ title }}</p>
    <p class="card-title__small" v-for="item in types" :key="item">{{ item }}</p>
  </div>
</template>

<script lang="js">
import { mapGetters } from 'vuex';

export default {
  name: 'CardPartnerType',
  props: {
    types: {
      type: Array,
      required: true
    }
  },
  computed: {
    title() {
      if (this.layer === 'partners') {
        return 'Тип партнера'
      }
      return 'Вид объекта'
    },
    ...mapGetters('layer', ['layer'])
  }
}
</script>

<style scoped></style>
